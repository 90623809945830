import React from "react";
import { NavBar } from "../components";
import { Intro, About } from "../sections";
import "./home.css";

const Home = () => {
  return (
    <>
      <header className={"header fixed-top"}>
        <NavBar />
      </header>
      <main>
        <div data-spy="scroll" data-target="#navCon">
          <div id="intro" className={"section section-intro"}>
            <Intro />
          </div>
          <div id="about" className={"section section-about"}>
            <About />
          </div>
          <div className={"section-footer"}></div>
        </div>
      </main>
    </>
  );
};

export default Home;
