import React, { useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import ParallaxMousemove from "react-parallax-mousemove";
import { FaBeer } from "react-icons/fa";
import { BiPlus } from "react-icons/bi";
import {
  AiOutlineBulb,
  AiOutlineBug,
  AiOutlineBranches,
  AiOutlineCode,
  AiOutlineNumber,
  AiOutlineCustomerService,
  AiOutlineEnvironment,
  AiOutlineExperiment,
  AiOutlineGithub,
  AiOutlineQrcode,
  AiOutlinePushpin,
  AiOutlineRocket,
  AiOutlineRobot,
} from "react-icons/ai";

import { useSpring, animated } from "react-spring";

import Typewriter from "typewriter-effect/dist/core";

import "./intro.css";

const calc = (x, y) => [
  -(x - window.innerWidth / 2),
  -(y - window.innerHeight / 2),
];

const transes = [
  (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`,
  (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 - 230}px,0)`,
  // (x, y) => `translate3d(${x / 6 - 250}px,${y / 6 - 200}px,0)`,
  (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`,
];

const getStyle = () => {
  return {
    // top: Math.random() * window.screen.height - 100 + "px",
    // left: Math.random() * window.innerWidth - 100 + "px",
  };
};

const getColor = () =>
  iconColors[Math.floor(Math.random() * iconColors.length)];

const poss = [
  { top: "200px", left: "100px" },
  { top: "300px", left: "515px" },
  { top: "500px", left: "320px" },
  { top: "700px", left: "400px" },
  { top: "200px", left: "610px" },
  { top: "400px", left: "170px" },
  { top: "590px", right: "180px" },
  { top: "120px", right: "545px" },
  { top: "690px", right: "140px" },
  { top: "300px", right: "160px" },
  { top: "500px", right: "420px" },
  { top: "430px", right: "290px" },
  { top: "240px", right: "90px" },
];

const iconColors = ["#FF4C60", "#04AA6D", "#1a73e8"];

const icons = [
  {
    name: BiPlus,
    size: 32,
    style: getStyle(),
    color: getColor(),
    iconStyle: {
      transform: `rotate(${Math.random() * 90}deg)`,
    },
  },
  {
    name: FaBeer,
    size: 27,
    style: getStyle(),
    color: getColor(),
    iconStyle: {
      transform: `rotate(${Math.random() * 90}deg)`,
    },
  },
  {
    name: AiOutlineBulb,
    size: 32,
    style: getStyle(),
    color: getColor(),
    iconStyle: {
      transform: `rotate(${Math.random() * 90}deg)`,
    },
  },
  {
    name: AiOutlineBranches,
    size: 32,
    style: getStyle(),
    color: getColor(),
    iconStyle: {
      transform: `rotate(${Math.random() * 90}deg)`,
    },
  },
  {
    name: AiOutlineCode,
    size: 32,
    style: getStyle(),
    color: getColor(),
    iconStyle: {
      transform: `rotate(${Math.random() * 90}deg)`,
    },
  },
  {
    name: AiOutlineNumber,
    size: 32,
    style: getStyle(),
    color: getColor(),
    iconStyle: {
      transform: `rotate(${Math.random() * 90}deg)`,
    },
  },
  {
    name: AiOutlineCustomerService,
    size: 32,
    style: getStyle(),
    color: getColor(),
    iconStyle: {
      transform: `rotate(${Math.random() * 90}deg)`,
    },
  },
  {
    name: AiOutlineEnvironment,
    size: 32,
    style: getStyle(),
    color: getColor(),
    iconStyle: {
      transform: `rotate(${Math.random() * 90}deg)`,
    },
  },
  {
    name: AiOutlineExperiment,
    size: 32,
    style: getStyle(),
    color: getColor(),
    iconStyle: {
      transform: `rotate(${Math.random() * 90}deg)`,
    },
  },
  {
    name: AiOutlineQrcode,
    size: 32,
    style: getStyle(),
    color: getColor(),
    iconStyle: {
      transform: `rotate(${Math.random() * 90}deg)`,
    },
  },
  {
    name: AiOutlinePushpin,
    size: 32,
    style: getStyle(),
    color: getColor(),
    iconStyle: {
      transform: `rotate(${Math.random() * 90}deg)`,
    },
  },
  {
    name: AiOutlineRocket,
    size: 32,
    style: getStyle(),
    color: getColor(),
    iconStyle: {
      transform: `rotate(${Math.random() * 90}deg)`,
    },
  },
  {
    name: AiOutlineRobot,
    size: 32,
    style: getStyle(),
    color: getColor(),
    iconStyle: {
      transform: `rotate(${Math.random() * 90}deg)`,
    },
  },
];

const getTrans = () => transes[Math.floor(Math.random() * transes.length)];

const Intro = () => {
  const introTxtCon = useRef();
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  useEffect(() => {
    // console.log(introTxtCon);
    if (introTxtCon) {
      let typewriter = new Typewriter(introTxtCon.current, {
        loop: true,
      });
      typewriter
        .typeString("I am a React Native Developer")
        .pauseFor(1000)
        .deleteChars(17)
        .typeString(".JS Developer")
        .pauseFor(1000)
        .start();
    }
  }, []);
  return (
    <section id={"intro-section"} className={"intro d-flex align-items-center"}>
      <Container>
        <div
          className={"parallax"}
          onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
        >
          {icons.map((i, idx) => {
            i.style.transform = props.xy.to(getTrans());
            return (
              <animated.div
                key={idx}
                style={{
                  ...{
                    position: "absolute",
                    zIndex: -1,
                    ...poss[idx],
                  },
                  ...i.style,
                }}
              >
                <i.name size={i.size} color={i.color} style={i.iconStyle} />
              </animated.div>
            );
          })}
        </div>

        <div className={"introCon align-items-center"}>
          <div className={"imgCon"}>
            <img src={"https://bolby-react.vercel.app/images/avatar-1.svg"} />
          </div>
          <div className={"nameCon"}>Nitish Sharma</div>
          <div
            ref={introTxtCon}
            id={"introTxtCon"}
            className={"intro-txt-con"}
          ></div>
        </div>
      </Container>
    </section>
  );
};

export default Intro;
