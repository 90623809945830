import React from "react";
import { Container, Row, Button, ProgressBar } from "react-bootstrap";
import resume from "../downloads/Nitish_Sharma_Resume.pdf";
import "./about.css";

const About = () => {
  return (
    <section id={"about-section"} className={"about-section"}>
      <Container data-aos="fade-up" data-aos-duration="1000" class={"about"}>
        <div className={"heading"}>
          <h2 className={"section-title"}>About me</h2>
        </div>
        <div className="spacer"></div>
        <Row>
          <div className={"col-md-3"}>
            <div className={"avatar-2 text-center text-md-left"}>
              <img
                class={"about-avatar"}
                src={"https://bolby-react.vercel.app/images/avatar-2.svg"}
              />
            </div>
          </div>
          <Container
            className={"about-col col-md-9 triangle-left-md triangle-top-sm"}
          >
            <Row>
              <div className={"col-md-6"}>
                <p>
                  I am Nitish Sharma, React.JS and React Native developer from
                  India. I have rich experience in web design, app development
                  and building and customization, also I am good with Linux OS.
                </p>
                <Button
                  variant={"primary"}
                  className={"rounded-pill"}
                  href={resume}
                >
                  Download CV
                </Button>
              </div>
              <div className={"skills col-md-6 mt-md-0 mt-3"}>
                <div className={"skill-con progress-wrapper"}>
                  <div className={"progress-head mb-2"}>
                    <div style={{ flex: 1 }}>React.JS</div>
                    <div>90%</div>
                  </div>

                  <ProgressBar now={90} />
                </div>

                <div className={"skill-con mt-3 progress-wrapper"}>
                  <div className={"progress-head mb-2"}>
                    <div style={{ flex: 1 }}>React-Native</div>
                    <div>90%</div>
                  </div>

                  <ProgressBar now={90} variant={"success"} />
                </div>

                <div className={"skill-con mt-3 progress-wrapper"}>
                  <div className={"progress-head mb-2"}>
                    <div style={{ flex: 1 }}>Firebase</div>
                    <div>85%</div>
                  </div>

                  <ProgressBar now={85} variant={"warning"} />
                </div>
              </div>
            </Row>
          </Container>
        </Row>
      </Container>
    </section>
  );
};

export default About;
